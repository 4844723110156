import './FullCart.scss';

import { AllEmojisEntity, EmojiEntity } from '../../../entities/emoji.entity';
import React, { useEffect, useState } from 'react';

import { Avatar } from '../../Users/Avatar/Avatar';
import { BubbleButton } from '../../Buttons/BubbleButton/BubbleButton';
import { CartNoteEntity } from '../../../entities/cart-note.entity';
import { CheckBoxInput } from '../../FormInputs/CheckBoxInput/CheckBoxInput';
import { CollectionEntity } from '../../../entities/collection.entity';
import { EmojiPanel } from '../../../components/Emoji/EmojiPanel/EmojiPanel';
import { Errors } from '../../../types/api-error.type';
import { FullCartCollection } from './FullCartCollection';
import { FullCartControls } from './FullCartControls';
import { FullCartEntity } from '../../../entities/full-cart.entity';
import { FullCartImage } from './FullCartImage';
import { FullCartNotes } from './FullCartNotes';
import { FullCartRate } from './FullCartRate';
import { FullCartTags } from './FullCartTags';
import { FullCartTitle } from './FullCartTitle';
import { Link } from 'react-router-dom';
import { RateEntity } from '../../../entities/rate.entity';
import { SecondaryButton } from '../../Buttons/SecondaryButton/SecondaryButton';
import { Tag } from '../../../entities/tag.entity';
import { URL_USER_PAGE } from '../../../constants/urls.constants';

type ChangingCart = {
	collection: CollectionEntity;
	title: string;
	imageFile: File | null;
	imageUrl: string | null;
	rate: RateEntity;
	notes: CartNoteEntity[];
	tags: Tag[];
};

export type CartEditingSubmitCallback = (
	cart: ChangingCart,
	doSendChangingNotification: boolean,
) => void;

type CartEditingProps = {
	isEditingLoading: boolean;
	submitEditingCart: CartEditingSubmitCallback;
	errors: Errors;
	setErrors: (errors: Errors) => void;
	collection: CollectionEntity;
	collectionChoices: CollectionEntity[];
	pickCollectionCallback: (collection: CollectionEntity) => void;
	collectionTags: Tag[];
};

type FullCartProps = {
	fullCart: FullCartEntity;
	isAuth: boolean;
	isMyCart: boolean;
	allEmojis: AllEmojisEntity;
	lastUsedEmojis: EmojiEntity[];
	pushEmojiCallback: (emojiName: string, add: boolean) => void;
	toPendingCartCallback?: () => void;
	repostCartCallback?: () => void;
	deleteCartCallback?: () => void;
	cartEditingProps: CartEditingProps;
};

export const FullCart: React.FC<FullCartProps> = ({
	fullCart,
	isAuth,
	isMyCart,
	allEmojis,
	lastUsedEmojis,
	pushEmojiCallback,
	toPendingCartCallback,
	repostCartCallback,
	deleteCartCallback,
	cartEditingProps,
}) => {
	const { cart, user } = fullCart;

	const [isCartChanging, setIsCartChanging] = useState<boolean>(false);
	const [isCartChanged, setIsCartChanged] = useState<boolean>(false);
	const [doSendChangingNotification, setDoSendChangingNotification] =
		useState<boolean>(false);
	const [imageFile, setImageFile] = useState<File | null>(null);
	const [cartChanging, setCartChanging] = useState<ChangingCart>({
		collection: cart.collection,
		title: cart.title,
		imageFile: null,
		imageUrl: cart.pictureUrl,
		notes: fullCart.notes,
		rate: cart.rate,
		tags: cart.tags,
	});

	const isAllowChanging = isMyCart;
	const isAllowChangingClass = isAllowChanging
		? 'full-cart__allow-changing'
		: '';

	const clearCartChanges = () => {
		cartEditingProps.pickCollectionCallback(cart.collection);
		setCartChanging({
			collection: cart.collection,
			title: cart.title,
			imageFile: null,
			imageUrl: cart.pictureUrl,
			notes: fullCart.notes,
			rate: cart.rate,
			tags: cart.tags,
		});
		setImageFile(null);
		setIsCartChanging(false);
		setIsCartChanged(false);
	};

	const isCartChangedEffect = () => {
		const { collection, title, imageUrl, rate, notes, tags } = cartChanging;
		if (
			collection.id !== cart.collection.id ||
			title !== cart.title ||
			imageFile !== null ||
			imageUrl !== cart.pictureUrl ||
			rate.id !== cart.rate.id ||
			notes !== fullCart.notes ||
			tags !== cart.tags
		) {
			setIsCartChanged(true);
		} else {
			setIsCartChanged(false);
		}
	};
	useEffect(isCartChangedEffect, [cartChanging, imageFile]);

	const imageError =
		cartEditingProps.errors['picture_url'] ||
		cartEditingProps.errors['picture'];
	const imageNode: React.ReactNode = (
		<FullCartImage
			imageUrl={cartChanging.imageUrl}
			imageFile={imageFile}
			setImageUrlCallback={(url) =>
				setCartChanging({ ...cartChanging, imageUrl: url })
			}
			setImageFileCallback={setImageFile}
			isAllowChanging={isAllowChanging}
			error={imageError && imageError[0]}
		/>
	);

	const emojiPanelNode: React.ReactNode = (
		<div className="full-cart__emoji-panel">
			<EmojiPanel
				emojiList={cart.emojis}
				pushEmojiCallback={pushEmojiCallback}
				allEmojis={allEmojis}
				lastUsedEmojis={lastUsedEmojis}
				isEmojiAddDisplayed={isAuth}
			/>
		</div>
	);

	const userInfoNode: React.ReactNode = (
		<Link
			className="full-cart__user-info-container"
			to={URL_USER_PAGE`${user.pageUrl}`}
		>
			<Avatar
				avatarUrl={user.avatar}
				username={user.username}
				size="default"
			/>
			<span className="full-cart__user-info-username">
				{user.username}
			</span>
		</Link>
	);

	const titleError = cartEditingProps.errors['title'];
	const titleNode = (
		<FullCartTitle
			isCartChanging={isCartChanging}
			setIsCartChanging={setIsCartChanging}
			title={cartChanging.title}
			setTitleCallback={(title) =>
				setCartChanging({ ...cartChanging, title })
			}
			isAllowChanging={isAllowChanging}
			isAllowChangingClass={isAllowChangingClass}
			error={titleError && titleError[0]}
		/>
	);

	const controlBlockNode: React.ReactNode = (
		<FullCartControls
			isAuth={isAuth}
			isMyCart={isMyCart}
			cartId={cart.id}
			toPendingCartCallback={toPendingCartCallback}
			repostCartCallback={repostCartCallback}
			deleteCartCallback={deleteCartCallback}
		/>
	);

	const collectionError = cartEditingProps.errors['collection_id'];
	const collectionCategoryNode: React.ReactNode = (
		<FullCartCollection
			isCartChanging={isCartChanging}
			setIsCartChanging={setIsCartChanging}
			collection={cartEditingProps.collection}
			collectionChoices={cartEditingProps.collectionChoices}
			pickCollectionCallback={(collection: CollectionEntity) => {
				cartEditingProps.pickCollectionCallback(collection);
				setCartChanging({ ...cartChanging, collection });
			}}
			isAllowChanging={isAllowChanging}
			isAllowChangingClass={isAllowChangingClass}
			error={collectionError && collectionError[0]}
		/>
	);

	const dateCreatedNode: React.ReactNode = (
		<span
			className="full-cart__date-created"
			title={`Дата добавления: ${fullCart.dateCreated}`}
		>
			{fullCart.dateCreatedRelative}
		</span>
	);

	const rateError = cartEditingProps.errors['rate'];
	const rateNode: React.ReactNode = (
		<FullCartRate
			isCartChanging={isCartChanging}
			setIsCartChanging={setIsCartChanging}
			collectionId={cart.collection.id}
			rate={cartChanging.rate}
			setRateCallback={(rate: RateEntity) => {
				setCartChanging({ ...cartChanging, rate });
			}}
			isAllowChanging={isAllowChanging}
			isAllowChangingClass={isAllowChangingClass}
			error={rateError && rateError[0]}
		/>
	);

	const notesError = cartEditingProps.errors['notes'];
	const notesNode = (
		<FullCartNotes
			isCartChanging={isCartChanging}
			setIsCartChanging={setIsCartChanging}
			notes={cartChanging.notes}
			setNotesCallback={(notes) => {
				setCartChanging({ ...cartChanging, notes });
			}}
			isAllowChanging={isAllowChanging}
			isAllowChangingClass={isAllowChangingClass}
			error={notesError && notesError[0]}
		/>
	);

	const tagsError = cartEditingProps.errors['tags'];
	const tagsNode: React.ReactNode = (
		<FullCartTags
			isCartChanging={isCartChanging}
			setIsCartChanging={setIsCartChanging}
			collectionId={cart.collection.id}
			collectionTags={cartEditingProps.collectionTags}
			tags={cartChanging.tags}
			setSelectedTagsCallback={(tags) => {
				setCartChanging({ ...cartChanging, tags });
			}}
			isAllowChanging={isAllowChanging}
			isAllowChangingClass={isAllowChangingClass}
			error={tagsError && tagsError[0]}
		/>
	);

	const editPanelNode: React.ReactNode = (isCartChanged ||
		isCartChanging) && (
		<div className="full-cart__edit-panel">
			<div className="full-cart__edit-panel__checkbox">
				<CheckBoxInput
					label={
						'Отправить подписчикам нотификацию об изменении карточки'
					}
					value={doSendChangingNotification}
					setValueCallback={setDoSendChangingNotification}
					size="small"
					direction="left"
					textColor="gray"
				/>
			</div>
			<div className="full-cart__edit-panel__buttons">
				<div className="full-cart__edit-panel__buttons__button">
					<BubbleButton
						title="Сохранить"
						disabled={
							!isCartChanged || cartEditingProps.isEditingLoading
						}
						isLoading={cartEditingProps.isEditingLoading}
						onClick={() =>
							cartEditingProps.submitEditingCart(
								{
									...cartChanging,
									imageFile,
								},
								doSendChangingNotification,
							)
						}
					/>
				</div>
				<div className="full-cart__edit-panel__buttons__button">
					<SecondaryButton
						title="Сбросить"
						onClick={clearCartChanges}
					/>
				</div>
			</div>
		</div>
	);

	return (
		<div className="full-cart">
			<div className="full-cart__two-blocks">
				<div className="full-cart__left-block">
					{imageNode}
					{emojiPanelNode}
				</div>
				<div className="full-cart__right-block">
					{userInfoNode}
					<div className="full-cart__title-block full-cart__two-columns">
						{titleNode}
						{controlBlockNode}
					</div>
					<div className="full-cart__two-columns">
						{collectionCategoryNode}
						{dateCreatedNode}
					</div>
					<div className="full-cart__rate">{rateNode}</div>
					<div className="full-cart__notes-container">
						{notesNode}
					</div>
					<div className="full-cart__tags-container">{tagsNode}</div>
				</div>
			</div>
			{editPanelNode}
		</div>
	);
};
