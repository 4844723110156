import './FullCartPopup.scss';

import { AllEmojisEntity, EmojiEntity } from '../../../entities/emoji.entity';
import {
	CartEditingSubmitCallback,
	FullCart,
} from '../../../components/Cart/FullCart/FullCart';
import {
	EVENT_MESSAGE_CART_CREATED,
	EVENT_MESSAGE_PENDING_CART_CREATED,
} from '../../../constants/events.constants';
import React, { useEffect, useState } from 'react';

import { CartEntity } from '../../../entities/cart.entity';
import { CollectionEntity } from '../../../entities/collection.entity';
import { CreateCart } from '../../CartControl/CreateCart';
import { CreatePendingCart } from '../../CartControl/PendingCart/CreatePendingCart';
import { DeleteCart } from '../../CartControl/DeleteCart';
import { Errors } from '../../../types/api-error.type';
import { FullCartEntity } from '../../../entities/full-cart.entity';
import { LoadingData } from '../../../components/LoadingData/LoadingData';
import { PendingCartEntity } from '../../../entities/pending-cart.entity';
import { PopupContainer } from '../../../components/PopupContainer/PopupContainer';
import { Tag } from '../../../entities/tag.entity';
import { editCart } from '../../../services/carts/edit-cart.service';
import { fetchCollectionChoices } from '../../../services/collections/fetch-collection-choices.service';
import { fetchCollectionTags } from '../../../services/collections/fetch-collection-tags.service';
import { useRootStore } from '../../../stores/RootStore';

type FullCartPopupProps = {
	fullCart: FullCartEntity | null;
	isMyCart: boolean;
	allEmojis: AllEmojisEntity;
	lastUsedEmojis: EmojiEntity[];
	pushEmojiCallback: (emojiName: string, add: boolean) => void;
	editedCallback: (cart: CartEntity) => void;
	deletedCallback: () => void;
};

export const FullCartPopup: React.FC<FullCartPopupProps> = ({
	fullCart,
	isMyCart,
	allEmojis,
	lastUsedEmojis,
	pushEmojiCallback,
	editedCallback,
	deletedCallback,
}) => {
	const { profileStore, eventsStore } = useRootStore();
	const { isAuth } = profileStore;

	const [errors, setErrors] = useState<Errors>({});
	const [isEditingLoading, setIsEditingLoading] = useState<boolean>(false);
	const [pickedCollection, setPickedCollection] =
		useState<CollectionEntity | null>(null);
	const [collectionChoices, setCollectionChoices] = useState<
		CollectionEntity[]
	>([]);
	const [collectionTags, setCollectionTags] = useState<Tag[]>([]);

	const [isToPendingCart, setIsToPendingCart] = useState<boolean>(false);
	const [isRepostingCart, setIsRepostingCart] = useState<boolean>(false);
	const [isDeletingCart, setIsDeletingCart] = useState<boolean>(false);

	const updateCollectionChoices = async () => {
		setCollectionChoices([]);
		const serviceResponse = await fetchCollectionChoices();
		if (serviceResponse.status === 'OK') {
			setCollectionChoices(serviceResponse.data);
		} else {
			eventsStore.pushUnknownErrorEvent();
		}
	};

	const fetchInitialDataEffect = () => {
		if (!isMyCart) {
			return;
		}

		updateCollectionChoices();
	};

	useEffect(fetchInitialDataEffect, []);

	const updateCollectionTags = async (collectionId: number) => {
		setCollectionTags([]);
		const serviceResponse = await fetchCollectionTags(
			collectionId.toString(),
		);
		if (serviceResponse.status === 'OK') {
			setCollectionTags(serviceResponse.data);
		} else {
			eventsStore.pushUnknownErrorEvent();
		}
	};

	const fetchCollectionDataEffect = () => {
		if (!pickedCollection || !isMyCart) {
			return;
		}

		updateCollectionTags(pickedCollection.id);
	};

	const pickedCollectionState = pickedCollection && pickedCollection.id;
	useEffect(fetchCollectionDataEffect, [pickedCollectionState]);

	const setInitialCollectionEffect = () => {
		if (!fullCart || pickedCollection !== null) {
			return;
		}

		setPickedCollection(fullCart.cart.collection);
	};

	const cartState = fullCart && fullCart.cart.id;
	useEffect(setInitialCollectionEffect, [cartState]);

	const submitEditingCart: CartEditingSubmitCallback = async (
		{ collection, title, rate, imageFile, imageUrl, notes, tags },
		doSendChangingNotification,
	) => {
		if (!fullCart) {
			return;
		}

		if (isEditingLoading) {
			return;
		}

		setErrors({});
		if (!title) {
			setErrors({ title: ['Нужно указать название'] });
			return;
		}

		setIsEditingLoading(true);
		const serviceResponse = await editCart(
			fullCart.cart.id,
			collection.id,
			title,
			rate.id,
			imageFile,
			imageUrl,
			notes,
			tags,
			doSendChangingNotification,
		);
		if (serviceResponse.status === 'OK') {
			editedCallback(serviceResponse.data);
		} else {
			setErrors(serviceResponse.data);
		}

		setIsEditingLoading(false);
	};

	const isLoading = fullCart === null;
	const topLineColor = fullCart ? `#${fullCart.cart.color}70` : ``;

	const createdPendingCallback = (pendingCart: PendingCartEntity) => {
		eventsStore.pushSuccessEvent(
			EVENT_MESSAGE_PENDING_CART_CREATED`${pendingCart.title}`,
		);
		setIsToPendingCart(false);
	};
	const toPendingCartNode: React.ReactNode = isToPendingCart && fullCart && (
		<PopupContainer
			isOpened={isToPendingCart}
			noDarkBackground={true}
			closePopupCallback={() => setIsToPendingCart(false)}
		>
			<CreatePendingCart
				createdCallback={createdPendingCallback}
				initialCart={fullCart.cart}
			/>
		</PopupContainer>
	);

	const createdCallback = (cart: CartEntity) => {
		eventsStore.pushSuccessEvent(
			EVENT_MESSAGE_CART_CREATED`${cart.title}${cart.collection.title}`,
		);
		setIsRepostingCart(false);
	};
	const repostCartNode: React.ReactNode = isRepostingCart && fullCart && (
		<PopupContainer
			isOpened={isRepostingCart}
			noDarkBackground={true}
			closePopupCallback={() => setIsRepostingCart(false)}
		>
			<CreateCart
				createdCallback={createdCallback}
				initialCart={fullCart.cart}
				isRepost={true}
			/>
		</PopupContainer>
	);

	const cartDeletedCallback = () => {
		if (!fullCart) {
			return;
		}
		setIsDeletingCart(false);
		deletedCallback();
	};
	const deleteCartNode: React.ReactNode = isDeletingCart && fullCart && (
		<PopupContainer
			isOpened={isDeletingCart}
			noDarkBackground={true}
			closePopupCallback={() => setIsDeletingCart(false)}
		>
			<DeleteCart
				cartId={fullCart.cart.id}
				cartTitle={fullCart.cart.title}
				cartDeletedCallback={cartDeletedCallback}
			/>
		</PopupContainer>
	);

	const fullCartNode: React.ReactNode = fullCart && pickedCollection && (
		<FullCart
			fullCart={fullCart}
			isAuth={isAuth}
			isMyCart={isMyCart}
			allEmojis={allEmojis}
			lastUsedEmojis={lastUsedEmojis}
			pushEmojiCallback={pushEmojiCallback}
			toPendingCartCallback={() => setIsToPendingCart(true)}
			repostCartCallback={() => setIsRepostingCart(true)}
			deleteCartCallback={() => setIsDeletingCart(true)}
			cartEditingProps={{
				isEditingLoading,
				submitEditingCart,
				errors,
				setErrors,
				collection: pickedCollection,
				collectionChoices,
				pickCollectionCallback: setPickedCollection,
				collectionTags,
			}}
		/>
	);

	const popupDisplay =
		!isRepostingCart && !isDeletingCart && !isToPendingCart
			? 'block'
			: 'none';

	return (
		<div>
			{toPendingCartNode}
			{repostCartNode}
			{deleteCartNode}
			<div className="full-cart-popup" style={{ display: popupDisplay }}>
				<div
					className="full-cart-popup__top-color-line"
					style={{ backgroundColor: topLineColor }}
				></div>
				<div className="full-cart-popup__content-container">
					<LoadingData isLoading={isLoading} />
					{fullCartNode}
				</div>
			</div>
		</div>
	);
};
