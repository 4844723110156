import './CheckBoxInput.scss';

import React, { useId } from 'react';

type CheckBoxInputProps = {
	label: string;
	value: boolean;
	setValueCallback: (value: boolean) => void;
	size?: 'small' | 'medium';
	direction?: 'left' | 'right';
	textColor?: 'gray' | 'black';
	error?: string | undefined;
};

export const CheckBoxInput: React.FC<CheckBoxInputProps> = ({
	label,
	value,
	setValueCallback,
	size = 'medium',
	direction = 'left',
	textColor = 'black',
	error = undefined,
}) => {
	const inputId = useId();

	const baseLabelClass = 'form-checkbox-input__label';
	const labelNode: React.ReactNode = (
		<label
			className={`${baseLabelClass} ${baseLabelClass}--${size} ${baseLabelClass}--${textColor}`}
			htmlFor={inputId}
		>
			{label}
		</label>
	);
	const inputNode: React.ReactNode = (
		<input
			className={`form-checkbox-input__input form-checkbox-input__input--${size}`}
			required
			type="checkbox"
			onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
				setValueCallback(e.target.checked)
			}
			id={inputId}
			checked={value}
		/>
	);

	const errorNode: React.ReactNode = error && (
		<span className="form-checkbox-input__error">{error}</span>
	);
	return (
		<div className="form-checkbox-input">
			<div
				className={`form-checkbox-input__block form-checkbox-input__block--${direction}`}
			>
				{labelNode}
				{inputNode}
			</div>
			{errorNode}
		</div>
	);
};
