//
// PATHS
//

export const URL_MAIN_PATH = '/';
export const URL_LOGIN_PATH = '/login';
export const URL_REGISTER_PATH = '/register';
export const URL_TELEGRAM_AUTH_PATH = '/telegram_auth';

export const URL_SETTINGS_PATH = '/settings';
export const URL_SETTINGS_PROFILE_SUBPATH = 'profile';
export const URL_SETTINGS_INVITE_SUBPATH = 'invite';

export const URL_NEWS_PATH = '/feed';
export const URL_PENDING_CART_PATH = '/pending';

export const URL_USER_COLLECTION_PATH = '/collection/:collectionId';
export const URL_CART_PATH = '/card/:cartId';
export const URL_USER_PATH = ':pageUrl';

export const URL_NOT_FOUND = '/notfound';

//
// DYNAMIC PAGES
//

export const URL_USER_PAGE = (
	strings: TemplateStringsArray,
	pageUrl: string,
): string => `/${pageUrl}`;
export const URL_USER_COLLECTION_PAGE = (
	strings: TemplateStringsArray,
	collectionId: string,
): string => `/collection/${collectionId}`;
export const URL_CART_PAGE = (
	strings: TemplateStringsArray,
	cartId: string,
): string => `/card/${cartId}`;
export const URL_REGISTER_BY_INVITATION = (
	strings: TemplateStringsArray,
	invitationCode: string,
): string => `${URL_REGISTER_PATH}?invitation_code=${invitationCode}`;

//
// API
//

const URL_API = '/api';

//
// API AUTH
//

const URL_API_AUTH = `${URL_API}/auth`;

export const URL_API_AUTH_INFO = `${URL_API_AUTH}/info/`;
export const URL_API_AUTH_LOGIN = `${URL_API_AUTH}/login/`;
export const URL_API_AUTH_REGISTER = `${URL_API_AUTH}/register/`;
export const URL_API_AUTH_TELEGRAM = `${URL_API_AUTH}/telegram_auth/`;
export const URL_API_AUTH_TELEGRAM_LOGIN = `${URL_API_AUTH}/telegram_login/`;
export const URL_API_AUTH_LOGOUT = `${URL_API_AUTH}/logout/`;

//
// API ACCOUNT
//

const URL_API_ACCOUNT = `${URL_API}/account`;

export const URL_API_ACCOUNT_INFO = (
	strings: TemplateStringsArray,
	pageUrl: string,
): string => `${URL_API_ACCOUNT}/${pageUrl}/info/`;

export const URL_API_ACCOUNT_EDIT = `${URL_API_ACCOUNT}/edit/`;
export const URL_API_ACCOUNT_INVITE_USERS_DATA = `${URL_API_ACCOUNT}/invite_users_data/`;

//
// API NOTIFICATIONS
//

const URL_API_NOTIFICATIONS = `${URL_API}/notifications`;

export const URL_API_NOTIFICATIONS_LIST = `${URL_API_NOTIFICATIONS}/list/`;
export const URL_API_NOTIFICATIONS_CHECK = `${URL_API_NOTIFICATIONS}/check/`;

//
// API SUBSCRIPTION
//

const URL_API_SUBSCRIPTION = `${URL_API}/subscriptions`;

export const URL_API_SUBSCRIPTION_SUBSCRIBE = (
	strings: TemplateStringsArray,
	userId: string,
): string => `${URL_API_SUBSCRIPTION}/${userId}/subscribe/`;
export const URL_API_SUBSCRIPTION_UNSUBSCRIBE = (
	strings: TemplateStringsArray,
	userId: string,
): string => `${URL_API_SUBSCRIPTION}/${userId}/unsubscribe/`;
export const URL_API_SUBSCRIPTION_SUBSCRIBERS = (
	strings: TemplateStringsArray,
	userId: string,
): string => `${URL_API_SUBSCRIPTION}/${userId}/subscribers/`;
export const URL_API_SUBSCRIPTION_SIGNABLES = (
	strings: TemplateStringsArray,
	userId: string,
): string => `${URL_API_SUBSCRIPTION}/${userId}/signables/`;

//
// API COLLECTION
//

const URL_API_COLLECTION = `${URL_API}/collection`;

export const URL_API_COLLECTION_LIST = `${URL_API_COLLECTION}/list/`;
export const URL_API_COLLECTION_FILTERS = `${URL_API_COLLECTION}/filters/`;
export const URL_API_COLLECTION_CREATE = `${URL_API_COLLECTION}/create/`;
export const URL_API_COLLECTION_CHOICES = `${URL_API_COLLECTION}/collection-choices/`;
export const URL_API_COLLECTION_EDIT = (
	strings: TemplateStringsArray,
	collectionId: string,
): string => `${URL_API_COLLECTION}/${collectionId}/edit/`;
export const URL_API_COLLECTION_DESCRIPTION_EDIT = (
	strings: TemplateStringsArray,
	collectionId: string,
): string => `${URL_API_COLLECTION}/${collectionId}/edit-description/`;
export const URL_API_COLLECTION_DELETE = (
	strings: TemplateStringsArray,
	collectionId: string,
): string => `${URL_API_COLLECTION}/${collectionId}/delete/`;
export const URL_API_COLLECTION_INFO = (
	strings: TemplateStringsArray,
	collectionId: string,
): string => `${URL_API_COLLECTION}/${collectionId}/info/`;
export const URL_API_COLLECTION_USER_INFO = (
	strings: TemplateStringsArray,
	collectionId: string,
): string => `${URL_API_COLLECTION}/${collectionId}/user-info/`;
export const URL_API_COLLECTION_TAGS = (
	strings: TemplateStringsArray,
	collectionId: string,
): string => `${URL_API_COLLECTION}/${collectionId}/collection-tags/`;

//
// API CART
//

const URL_API_CART = `${URL_API}/cart`;

export const URL_API_CART_CREATE = `${URL_API_CART}/create/`;
export const URL_API_CART_LIST = `${URL_API_CART}/list/`;
export const URL_API_CART_FILTERS = `${URL_API_CART}/filters/`;
export const URL_API_CART_RANDOM = `${URL_API_CART}/random/`;
export const URL_API_CART_GET_FULL = (
	strings: TemplateStringsArray,
	cartId: string,
): string => `${URL_API_CART}/${cartId}/full-cart/`;
export const URL_API_CART_EDIT = (
	strings: TemplateStringsArray,
	cartId: string,
): string => `${URL_API_CART}/${cartId}/edit/`;
export const URL_API_CART_DELETE = (
	strings: TemplateStringsArray,
	cartId: string,
): string => `${URL_API_CART}/${cartId}/delete/`;

//
// API PENDING CART
//

const URL_API_PENDING_CART = `${URL_API}/pending`;

export const URL_API_PENDING_CART_CREATE = `${URL_API_PENDING_CART}/create/`;
export const URL_API_PENDING_CART_FILTERS = `${URL_API_PENDING_CART}/filters/`;
export const URL_API_PENDING_CART_LIST = `${URL_API_PENDING_CART}/list/`;
export const URL_API_PENDING_CART_EDIT = (
	strings: TemplateStringsArray,
	cartId: string,
): string => `${URL_API_PENDING_CART}/${cartId}/edit/`;
export const URL_API_PENDING_CART_DELETE = (
	strings: TemplateStringsArray,
	cartId: string,
): string => `${URL_API_PENDING_CART}/${cartId}/delete/`;

//
// API EMOJIS
//

const URL_API_EMOJI = `${URL_API}/emoji`;

export const URL_API_EMOJI_PUSH = `${URL_API_EMOJI}/push/`;
export const URL_API_EMOJI_LIST = `${URL_API_EMOJI}/list/`;

//
// API NEWS FEED
//

const URL_API_NEWSFFED = `${URL_API}/newsfeed`;

export const URL_API_NEWSFFED_LIST = `${URL_API_NEWSFFED}/list/`;
export const URL_API_NEWSFFED_FILTERS = `${URL_API_NEWSFFED}/filters/`;
export const URL_API_NEWSFFED_CHECK = `${URL_API_NEWSFFED}/check/`;
