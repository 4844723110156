import './NotesInput.scss';
import '../FormInputs.scss';

import React, { useState } from 'react';

import { CartNoteEntity } from '../../../entities/cart-note.entity';
import { NoteControlIcon } from './NoteControlIcon';
import { NoteInput } from './NoteInput';
import arrowDownIconSrc from '../../../assets/icons/common/arrow_down.svg';
import arrowUpIconSrc from '../../../assets/icons/common/arrow_up.svg';
import crossedEyeIconSrc from '../../../assets/icons/cart/crossed-eye.svg';
import deleteIconSrc from '../../../assets/icons/cart/delete.svg';

type NotesInputProps = {
	notes: CartNoteEntity[];
	setNotesCallback: (notes: CartNoteEntity[]) => void;
	error: string | undefined;
};

export const NotesInput: React.FC<NotesInputProps> = ({
	notes,
	setNotesCallback,
	error,
}) => {
	const newNoteTemplate: CartNoteEntity = {
		id: 0,
		text: '',
		isSpoiler: false,
	};
	const [newNote, setNewNote] = useState<CartNoteEntity>({
		...newNoteTemplate,
	});
	const [noteIndexShouldFocused, setNoteIndexShouldFocused] = useState<
		number | null
	>(null);

	const maxNotesCount = 20;

	const renderNote = (
		note: CartNoteEntity,
		index: number,
	): React.ReactNode => {
		const setNoteCallback = (
			note: CartNoteEntity,
			extraValue: string | undefined,
		) => {
			const newNotes = [...notes];
			newNotes[index] = note;

			if (extraValue) {
				const extraNote = { ...newNoteTemplate, text: extraValue };
				const extraNoteIndex = index + 1;
				newNotes.splice(extraNoteIndex, 0, extraNote);
				setNoteIndexShouldFocused(extraNoteIndex);
			}

			setNotesCallback(newNotes);
		};
		const clickAwayCallback = () => {
			if (note.text.length === 0) {
				const newNotes = [...notes];
				newNotes.splice(index, 1);
				setNotesCallback(newNotes);
			}
		};
		const deleteNote = () => {
			const newNotes = [...notes];
			newNotes.splice(index, 1);
			setNotesCallback(newNotes);
		};
		const setIsSpoiler = () => {
			const newNotes = [...notes];
			const note = newNotes[index];
			note.isSpoiler = !note.isSpoiler;
			newNotes[index] = note;
			setNotesCallback(newNotes);
		};
		const moveNote = (positionsNum: number) => {
			const newNotes = [...notes];
			const fromIndex = index;
			const toIndex = index + positionsNum;
			newNotes.splice(toIndex, 0, newNotes.splice(fromIndex, 1)[0]);
			setNotesCallback(newNotes);
		};

		const moveUpButtonNode =
			index !== 0 ? (
				<NoteControlIcon
					title="Подвинуть вверх"
					src={arrowUpIconSrc}
					onClick={() => moveNote(-1)}
					width="16px"
				/>
			) : (
				<div></div>
			);
		const moveDownButtonNode =
			index !== notes.length ? (
				<NoteControlIcon
					title="Подвинуть вниз"
					src={arrowDownIconSrc}
					onClick={() => moveNote(1)}
					width="16px"
				/>
			) : (
				<div></div>
			);

		const isActiveSpoilerOption = note.isSpoiler
			? 'notes-input__control-option--active'
			: '';
		const spoilerOptionTitle = note.isSpoiler
			? 'Убрать спойлер'
			: 'Отметить как спойлер';
		return (
			<div className="notes-input__note" key={`note__${index}`}>
				<div className="notes-input__note-input">
					<NoteInput
						note={note}
						setNoteCallback={setNoteCallback}
						clickAwayCallback={clickAwayCallback}
						shouldFocused={noteIndexShouldFocused === index}
						onFocusCallback={() => setNoteIndexShouldFocused(null)}
					/>
				</div>
				<div className="notes-input__controls">
					{moveUpButtonNode}
					<div className="notes-input__controls-horizon">
						<NoteControlIcon
							title={spoilerOptionTitle}
							src={crossedEyeIconSrc}
							onClick={setIsSpoiler}
							extraClass={isActiveSpoilerOption}
						/>
						<NoteControlIcon
							title="Удалить"
							src={deleteIconSrc}
							onClick={deleteNote}
						/>
					</div>
					{moveDownButtonNode}
				</div>
			</div>
		);
	};
	const notesNodes: React.ReactNode[] = notes.map(renderNote);

	const setNewNoteCallback = (note: CartNoteEntity) => {
		setNewNote({ ...note });
	};
	const addNewNoteCallback = () => {
		if (newNote.text.length === 0) {
			return;
		}

		setNotesCallback([...notes, newNote]);
		setNewNote({ ...newNoteTemplate });
	};

	const isNewNoteAvailable = notes.length < maxNotesCount;
	const newNoteNode: React.ReactNode = isNewNoteAvailable && (
		<div className="notes-input__note-input">
			<NoteInput
				note={newNote}
				placeholder={'Добавь заметку...'}
				setNoteCallback={setNewNoteCallback}
				clickAwayCallback={addNewNoteCallback}
				onEnterKeyPress={addNewNoteCallback}
			/>
		</div>
	);

	const errorNode: React.ReactNode = error && (
		<span className="form-input__error">{error}</span>
	);
	return (
		<div className="notes-input-container">
			<label className="form-input__label">Заметки</label>
			<div className="notes-input">
				{notesNodes}
				{newNoteNode}
			</div>
			{errorNode}
		</div>
	);
};
