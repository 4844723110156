import './HorizontalCart.scss';

import { AllEmojisEntity, EmojiEntity } from '../../../entities/emoji.entity';
import {
	RATES_CHOICES,
	RATE_IMAGE_HEIGHT_MAP,
} from '../../../constants/carts.constants';
import React, { useRef, useState } from 'react';
import {
	URL_CART_PAGE,
	URL_USER_COLLECTION_PAGE,
} from '../../../constants/urls.constants';

import { CartControlButton } from '../CartControlButton/CartControlButton';
import { CartEntity } from '../../../entities/cart.entity';
import { EmojiPanel } from '../../Emoji/EmojiPanel/EmojiPanel';
import { Icon } from '../../Icon/Icon';
import { Image } from '../../Image/Image';
import { Link } from 'react-router-dom';
import { Tag } from '../../../entities/tag.entity';
import deleteIconSrc from '../../../assets/icons/cart/delete.svg';
import { getCartColorLineBorder } from '../../../utils/getCartColorLineBorder';
import { minifyString } from '../../../utils/minifyString';
import repostIconSrc from '../../../assets/icons/cart/repost.svg';
import textIconSrc from '../../../assets/icons/cart/text.svg';
import timeIconSrc from '../../../assets/icons/cart/time.svg';
import { useClickAway } from 'react-use';
import { useLimitItems } from '../../../utils/useLimitItems';

type HorizontalCartProps = {
	cart: CartEntity;
	isMyCart: boolean;
	isAuth: boolean;
	maxTagsCount: number;
	lastUsedEmojis?: EmojiEntity[];
	allEmojis?: AllEmojisEntity;
	openFullCartCallback: () => void;
	pushEmojiCallback?: (emojiName: string, add: boolean) => void;
	tagsIdsPicked?: number[];
	deleteCartCallback?: () => void;
	repostCartCallback?: () => void;
	toPendingCartCallback?: () => void;
	colorLinePosition?: 'top' | 'left';
};

export const HorizontalCart: React.FC<HorizontalCartProps> = ({
	cart,
	isMyCart,
	isAuth,
	lastUsedEmojis,
	allEmojis,
	tagsIdsPicked,
	maxTagsCount,
	openFullCartCallback,
	deleteCartCallback,
	repostCartCallback,
	pushEmojiCallback,
	toPendingCartCallback,
	colorLinePosition = 'top',
}) => {
	const [isCartFocused, setIsCartFocused] = useState<boolean>(false);

	const refPopup = useRef<HTMLDivElement>(null);
	useClickAway(refPopup, () => setIsCartFocused(false));

	const cartTitle = minifyString(cart.title, 64);
	const cartLineBorder = getCartColorLineBorder(isCartFocused, cart.color, 4);
	const cartBackgroundColor = `#${cart.color}09`;
	const cartBackgroundSize = isCartFocused ? '100%' : '0%';
	const cartImageHeight =
		RATE_IMAGE_HEIGHT_MAP[cart.rate.id as unknown as RATES_CHOICES];

	const fullCartUrl = URL_CART_PAGE`${cart.id.toString()}`;
	const openFullCart = (e: React.MouseEvent<HTMLAnchorElement>) => {
		if (e.metaKey) {
			return;
		}
		e.preventDefault();
		openFullCartCallback();
	};

	const toPendingCartControl: React.ReactNode = isAuth &&
		toPendingCartCallback && (
			<CartControlButton
				iconScr={timeIconSrc}
				title="Отложить"
				callback={toPendingCartCallback}
				key={`${cart.id}__pending`}
			/>
		);

	const repostCartControl: React.ReactNode = isAuth && repostCartCallback && (
		<CartControlButton
			iconScr={repostIconSrc}
			title="Добавить к себе"
			callback={repostCartCallback}
			key={`${cart.id}__repost`}
		/>
	);

	const deleteCartControl: React.ReactNode = isMyCart &&
		deleteCartCallback && (
			<CartControlButton
				iconScr={deleteIconSrc}
				title="Удалить"
				callback={deleteCartCallback}
				key={`${cart.id}__delete`}
			/>
		);

	const myCartControls: React.ReactNode[] = [deleteCartControl];
	const otherCartControls: React.ReactNode[] = [
		toPendingCartControl,
		repostCartControl,
	];
	const cartControls = isMyCart ? myCartControls : otherCartControls;
	const cartControlsNode: React.ReactNode | null = isCartFocused ? (
		<div className="hcart__controls-container">
			<div className="hcart__controls">{cartControls}</div>
		</div>
	) : null;

	const cartImageNode: React.ReactNode = (
		<Link
			className="hcart__image_container"
			to={fullCartUrl}
			onClick={openFullCart}
		>
			<div className="hcart__image">
				<Image src={cart.thumbnailUrl} height={cartImageHeight} />
			</div>
		</Link>
	);

	const titleNode: React.ReactNode = (
		<Link
			className="hcart__title"
			title={cart.title}
			to={fullCartUrl}
			onClick={openFullCart}
		>
			{cartTitle}
		</Link>
	);

	const rateNode: React.ReactNode = (
		<Link
			to={{
				pathname: URL_USER_COLLECTION_PAGE`${cart.collection.id.toString()}`,
				search: `?rate=${cart.rate.id}`,
			}}
		>
			<p className="hcart__rate">{cart.rate.title}</p>
		</Link>
	);

	const notesNode: React.ReactNode = cart.notesCount > 0 && (
		<Link className="hcart__notes" to={fullCartUrl} onClick={openFullCart}>
			<div className="hcart__notes-icon">
				<Icon src={textIconSrc} title={'Заметки'} sizePx={16} />
			</div>
			Заметок: {cart.notesCount}
		</Link>
	);

	const sortedCartTags = cart.tags.sort((a: Tag, b: Tag) => {
		if (tagsIdsPicked === undefined) {
			return 0;
		}

		const aTagPicked =
			tagsIdsPicked.includes(a.pk) && !tagsIdsPicked.includes(b.pk);
		const bTagPicked =
			!tagsIdsPicked.includes(a.pk) && tagsIdsPicked.includes(b.pk);
		if (aTagPicked) {
			return -1;
		} else if (bTagPicked) {
			return 1;
		}

		return 0;
	});
	const [isTagsLimited, limitTagsCount, limitedTags] = useLimitItems(
		sortedCartTags,
		maxTagsCount,
	);

	const renderTagNode = (tag: Tag): React.ReactNode => {
		const tagTitle = minifyString(tag.name, 25);
		const isTagPicked =
			tagsIdsPicked !== undefined && tagsIdsPicked.includes(tag.pk);
		const tagPickedClass = isTagPicked ? 'htag--picked' : '';
		return (
			<div className={`htag ${tagPickedClass}`} key={tag.pk}>
				<Link
					to={{
						pathname: URL_USER_COLLECTION_PAGE`${cart.collection.id.toString()}`,
						search: `?tag_id=${tag.pk}`,
					}}
				>
					<p title={tag.name} className="htag__name">
						{tagTitle}
					</p>
				</Link>
			</div>
		);
	};
	const tagsNodes: Array<React.ReactNode> = limitedTags.map(renderTagNode);

	const allTagsString = cart.tags.map((tag) => tag.name).join(', ');
	const extraTagsNode: React.ReactNode = isTagsLimited && (
		<a
			href={fullCartUrl}
			onClick={openFullCart}
			className="htag__extra"
			title={allTagsString}
		>
			<p className="htag__extra_title">{`еще ${limitTagsCount}`}</p>
		</a>
	);

	const isEmojiAddDisplayed: boolean = isAuth && isCartFocused;
	const emojiPanelNode: React.ReactNode = allEmojis &&
		lastUsedEmojis &&
		pushEmojiCallback && (
			<EmojiPanel
				emojiList={cart.emojis}
				pushEmojiCallback={pushEmojiCallback}
				allEmojis={allEmojis}
				lastUsedEmojis={lastUsedEmojis}
				isEmojiAddDisplayed={isEmojiAddDisplayed}
			/>
		);

	const colorLineStyle = {
		borderTop: colorLinePosition === 'top' ? cartLineBorder : 'none',
		borderLeft: colorLinePosition === 'left' ? cartLineBorder : 'none',
	};
	const colorBackgroundStyle = {
		backgroundColor: cartBackgroundColor,
		height: colorLinePosition === 'top' ? cartBackgroundSize : '100%',
		width: colorLinePosition === 'top' ? '100%' : cartBackgroundSize,
	};

	return (
		<div className="hcart__container">
			<div
				className="hcart"
				style={colorLineStyle}
				onMouseOver={() => setIsCartFocused(true)}
				onTouchStart={() => setIsCartFocused(true)}
				onMouseLeave={() => setIsCartFocused(false)}
				ref={refPopup}
			>
				<div
					className="hcart__color_background"
					style={colorBackgroundStyle}
				></div>
				{cartControlsNode}
				{cartImageNode}
				<div className="hcart__content_container">
					<div className="hcart__title_container">
						{titleNode}
						{rateNode}
						{notesNode}
					</div>
					<div className="htags_container">
						{tagsNodes}
						{extraTagsNode}
					</div>
				</div>
				<div className="hcart__emoji-panel-container">
					{emojiPanelNode}
				</div>
			</div>
		</div>
	);
};
