import { RepositoryResponse, request } from '../request.repository';

import { CartEntity } from '../../entities/cart.entity';
import { URL_API_CART_RANDOM } from '../../constants/urls.constants';

export interface ServiceResponseSuccess {
	status: 'OK';
	data: Array<CartEntity>;
}

export interface ServiceResponseError {
	status: 'ERROR';
}

async function FetchRandomCartsService(
	currentCartsIds?: Array<number> | null,
): Promise<ServiceResponseSuccess | ServiceResponseError> {
	let url = URL_API_CART_RANDOM;
	if (currentCartsIds) {
		const currentCartsIdsParams = new URLSearchParams(
			currentCartsIds.map((x) => {
				return ['current_card_id', x.toString()];
			}),
		);
		url = `${url}?${currentCartsIdsParams}`;
	}

	const method = 'GET';
	const options = {
		method,
	};

	const response: RepositoryResponse = await request(url, options);

	if (response.status === 'OK') {
		const data: CartEntity[] = response.data.content.data;
		return {
			status: 'OK',
			data,
		};
	}

	return { status: 'ERROR' };
}

export { FetchRandomCartsService };
