import './FullCart.scss';

import React, { useState } from 'react';

import { CartNoteEntity } from '../../../entities/cart-note.entity';
import Linkify from 'react-linkify';
import { NotesInput } from '../../FormInputs/NotesInput/NotesInput';
import crossedEyeIconSrc from '../../../assets/icons/cart/crossed-eye.svg';

type FullCartNotesProps = {
	isCartChanging: boolean;
	setIsCartChanging: (isChanging: boolean) => void;

	notes: CartNoteEntity[];
	setNotesCallback: (notes: CartNoteEntity[]) => void;

	isAllowChanging: boolean;
	isAllowChangingClass: string;
	error: string | undefined;
};

export const FullCartNotes: React.FC<FullCartNotesProps> = ({
	isCartChanging,
	setIsCartChanging,
	notes,
	setNotesCallback,
	isAllowChanging,
	isAllowChangingClass,
	error,
}) => {
	const [isChanging, setIsChanging] = useState<boolean>(false);
	const [revealedSpoilersIds, setRevealedSpoilersIds] = useState<number[]>(
		[],
	);

	const inputNode: React.ReactNode = (
		<NotesInput
			notes={notes}
			setNotesCallback={setNotesCallback}
			error={error}
		/>
	);

	const renderNoteNode = (
		note: CartNoteEntity,
		index: number,
	): React.ReactNode => {
		const spoilerNode: React.ReactNode = (
			<div
				className="full-cart__note full-cart__note--spoiler"
				onClick={() =>
					setRevealedSpoilersIds([...revealedSpoilersIds, note.id])
				}
			>
				<div
					className="full-cart__note-spoiler-img"
					title="Показать спойлер"
				>
					<img
						className="full-cart__note-spoiler-img-icon"
						src={crossedEyeIconSrc}
						alt="spoiler"
					/>
				</div>{' '}
				Заметка содержит спойлер! Нажми, чтобы показать...
			</div>
		);

		const textNode: React.ReactNode = (
			<div className="full-cart__note">
				<Linkify>{note.text}</Linkify>
			</div>
		);

		const isShowNote =
			!note.isSpoiler || revealedSpoilersIds.includes(note.id);
		const noteContent = isShowNote ? textNode : spoilerNode;
		return <div key={`note__${index}`}>{noteContent}</div>;
	};
	const contentNode: React.ReactNode =
		notes.length > 0 ? (
			notes.map(renderNoteNode)
		) : (
			<span className="full-cart__notes-no">Без заметок</span>
		);
	const displayNode: React.ReactNode = (
		<div>
			<span
				className={`full-cart__label ${isAllowChangingClass}`}
				onClick={() => {
					setIsChanging(isAllowChanging);
					setIsCartChanging(isAllowChanging);
				}}
			>
				Заметки
			</span>
			<div className="full-cart__notes">{contentNode}</div>
		</div>
	);

	const finalNode: React.ReactNode =
		isChanging && isCartChanging ? inputNode : displayNode;
	return <div>{finalNode}</div>;
};
