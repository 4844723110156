import './MainPage.scss';

import React, { useEffect, useState } from 'react';

import { CartEntity } from '../../entities/cart.entity';
import { FetchRandomCartsService } from '../../services/carts/fetch-random-carts.service';
import { HorizontalCart } from '../../components/Cart/HorizontalCart/HorizontalCart';
import { SecondaryButton } from '../../components/Buttons/SecondaryButton/SecondaryButton';
import { URL_CART_PAGE } from '../../constants/urls.constants';
import logoImg from '../../assets/images/logo512.png';
import { observer } from 'mobx-react-lite';
import { useRootStore } from '../../stores/RootStore';
import { useWindowScroll } from 'react-use';

const descFirstWords: string[] = [
	'знаешь',
	'ищешь',
	'выбираешь',
	'думаешь',
	'смотришь',
	'гуглишь',
	'сохраняешь',
	'откладываешь',
	'оцениваешь',
];
const descSecondWords: string[] = [
	'посмотреть',
	'почитать',
	'поиграть',
	'поесть',
	'посетить',
	'полистать',
	'изучить',
];
const descColors: string[] = [
	'#197278',
	'#772E25',
	'#393424',
	'#5F0A87',
	'#2A2A72',
	'#AF125A',
	'#0077B6',
	'#662C91',
	'#B74A01',
];

export const DescriptionWithRandomWords: React.FC = () => {
	const { y } = useWindowScroll();
	const [descPrevValueState, setDescPrevValueState] = useState<number>(0);
	const [descFirstWord, setDescFirstWord] = useState<string>('');
	const [descSecondWord, setDescSecondWord] = useState<string>('');
	const [descFirstWordColor, setDescFirstWordColor] =
		useState<string>('black');
	const [descSecondWordColor, setDescSecondWordColor] =
		useState<string>('black');
	const [descFirstWordRotate, setDescFirstWordRotate] =
		useState<string>('rotate(0deg)');
	const [descSecondWordRotate, setDescSecondWordRotate] =
		useState<string>('rotate(0deg)');

	const getRandomEl = (array: string[]): string => {
		return array[Math.floor(Math.random() * array.length)];
	};
	const getRandomInt = (min: number, max: number): number => {
		min = Math.ceil(min);
		max = Math.floor(max);
		return Math.floor(Math.random() * (max - min + 1)) + min;
	};

	const updateDescriptionEffect = () => {
		const changingThresholdValue = 50;
		if (Math.abs(y - descPrevValueState) < changingThresholdValue) return;

		setDescFirstWord(getRandomEl(descFirstWords));
		setDescSecondWord(getRandomEl(descSecondWords));
		setDescFirstWordColor(getRandomEl(descColors));
		setDescSecondWordColor(getRandomEl(descColors));
		setDescFirstWordRotate(`rotate(${getRandomInt(-3, 3)}deg)`);
		setDescSecondWordRotate(`rotate(${getRandomInt(-3, 3)}deg)`);
		setDescPrevValueState(y);
	};
	useEffect(updateDescriptionEffect, [y]);

	const descFirstWordStyle: React.CSSProperties = {
		'--before-bg-color': descFirstWordColor,
		'--before-rotate': descFirstWordRotate,
	} as React.CSSProperties;
	const descSecondWordStyle: React.CSSProperties = {
		'--before-bg-color': descSecondWordColor,
		'--before-rotate': descSecondWordRotate,
	} as React.CSSProperties;

	return (
		<div className="description-block">
			<h2 className="description-block__text">
				Это когда{' '}
				<span
					className="description-block__word"
					style={descFirstWordStyle}
				>
					{descFirstWord}
				</span>
				, что{' '}
				<span
					className="description-block__word"
					style={descSecondWordStyle}
				>
					{descSecondWord}
				</span>
			</h2>
		</div>
	);
};

export const MainPage: React.FC = observer(() => {
	const { eventsStore } = useRootStore();
	const [randomCarts, setRandomCarts] = useState<Array<CartEntity> | null>(
		null,
	);

	const fetchRandomCarts = async () => {
		const currentCartsIds = randomCarts && randomCarts.map((c) => c.id);
		const serviceResponse = await FetchRandomCartsService(currentCartsIds);
		if (serviceResponse.status === 'OK') {
			setRandomCarts(serviceResponse.data);
		} else {
			eventsStore.pushUnknownErrorEvent();
		}
	};

	const fetchRandomCartsEffect = () => {
		fetchRandomCarts();
	};

	useEffect(fetchRandomCartsEffect, []);

	const renderCart = (cart: CartEntity): React.ReactNode => {
		const fullCartUrl = URL_CART_PAGE`${cart.id.toString()}`;
		return (
			<div className="random-cards-block__card" key={cart.id}>
				<HorizontalCart
					cart={cart}
					isMyCart={false}
					isAuth={false}
					maxTagsCount={3}
					openFullCartCallback={() =>
						window.open(fullCartUrl, '_blank')
					}
				/>
			</div>
		);
	};

	const randomCardsNodes: React.ReactNode[] | null =
		randomCarts && randomCarts.map(renderCart);

	return (
		<div className="main-page">
			<div className="title-block">
				<div className="title-block__image">
					<img src={logoImg} alt="" style={{ width: '100%' }} />
				</div>
				<h1 className="title-block__title">Godnohub</h1>
				<p className="title-block__description">
					Нереальная годнота, конечно, но что это?
				</p>
				<p>⏬</p>
			</div>

			<DescriptionWithRandomWords />

			<div className="random-cards-block">
				<h2 className="random-cards-block__title">
					🎲 Случайные карточки пользователей
				</h2>
				<div className="random-cards-block__cards">
					{randomCardsNodes}
				</div>
				<SecondaryButton
					title="Еще карточек"
					onClick={fetchRandomCarts}
				/>
			</div>

			<div className="contacts-block">
				<h2 className="contacts-block__title">Контакты</h2>
				<a
					className="contacts-block__email"
					href="mailto:tarasrodionov@gmail.com"
				>
					tarasrodionov@gmail.com
				</a>
			</div>
		</div>
	);
});
