import './BubbleButton.scss';

import React from 'react';

type sizes = 'small' | 'default';

type BubbleButtonProps = {
	title: string;
	disabled?: boolean;
	isLoading?: boolean;
	size?: sizes;
	onClick?: () => void;
};

export const BubbleButton: React.FC<BubbleButtonProps> = ({
	title,
	isLoading = false,
	disabled = false,
	size = 'default',
	onClick,
}) => {
	const sizeClass = size === 'small' ? 'bubble-button--small' : '';
	const submitLoadingClass = isLoading ? 'bubble-button--loading' : '';

	return (
		<button
			disabled={disabled}
			className={`bubble-button ${submitLoadingClass} ${sizeClass}`}
			onClick={onClick}
		>
			{title}
		</button>
	);
};
