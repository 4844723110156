import './NotesInput.scss';

import React from 'react';

type NoteControlIconProps = {
	title: string;
	src: string;
	onClick: () => void;
	extraClass?: string;
	width?: string;
};

export const NoteControlIcon: React.FC<NoteControlIconProps> = ({
	title,
	src,
	onClick,
	extraClass,
	width,
}) => {
	return (
		<div
			className={`notes-input__control-option ${extraClass}`}
			title={title}
			style={{ width }}
		>
			<img
				className="notes-input__control-option-icon"
				src={src}
				alt={title}
				onClick={onClick}
			/>
		</div>
	);
};
