import './NotesInput.scss';
import '../FormInputs.scss';

import React, { useEffect, useRef, useState } from 'react';

import { CartNoteEntity } from '../../../entities/cart-note.entity';
import { useAutosizeTextArea } from '../../../utils/useAutosizeTextArea';
import { useClickAway } from 'react-use';

type NoteProps = {
	note: CartNoteEntity;
	placeholder?: string;
	setNoteCallback: (notes: CartNoteEntity, extraValue?: string) => void;
	clickAwayCallback: () => void;
	onEnterKeyPress?: () => void;
	shouldFocused?: boolean;
	onFocusCallback?: () => void;
};

export const NoteInput: React.FC<NoteProps> = ({
	note,
	placeholder,
	setNoteCallback,
	clickAwayCallback,
	onEnterKeyPress,
	shouldFocused,
	onFocusCallback,
}) => {
	const [lettersCount, setLettersCount] = useState<number>(0);

	const maxNoteLength = 200;

	const refNote = useRef<HTMLTextAreaElement>(null);
	useClickAway(refNote, clickAwayCallback);
	useAutosizeTextArea(refNote.current, note.text);

	useEffect(() => {
		if (refNote.current) {
			refNote.current.style.height = '0px';
			const scrollHeight = refNote.current.scrollHeight;
			refNote.current.style.height = scrollHeight + 'px';
		}
	}, [refNote]);

	useEffect(() => {
		if (shouldFocused && onFocusCallback) {
			refNote.current?.focus();
			onFocusCallback();
		}
	}, [shouldFocused, onFocusCallback]);

	const isFocused: boolean = document.activeElement === refNote.current;
	const lettersCountNode: React.ReactNode = isFocused && (
		<span className="note-input__note__letters-count">
			{lettersCount}/{maxNoteLength}
		</span>
	);

	return (
		<div className="note-input__note">
			<textarea
				ref={refNote}
				className="note-input__note-text-input"
				placeholder={placeholder}
				onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
					const targetValue = e.target.value;
					const noteValue = targetValue.slice(0, maxNoteLength);
					const extraValue = targetValue.slice(
						maxNoteLength,
						targetValue.length,
					);
					setNoteCallback({ ...note, text: noteValue }, extraValue);
					setLettersCount(noteValue.length);
				}}
				onKeyDown={(e: React.KeyboardEvent<HTMLTextAreaElement>) => {
					if (e.key === 'Enter') {
						e.preventDefault();
						onEnterKeyPress && onEnterKeyPress();
					}
				}}
				value={note.text}
			></textarea>
			{lettersCountNode}
		</div>
	);
};
