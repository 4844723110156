import './Emoji.scss';

import { EmojiEntity } from '../../../entities/emoji.entity';
import React from 'react';

type emojiSizes = 'default' | 'small';

type EmojiProps = {
	emojiEntity: EmojiEntity;
	size?: emojiSizes;
};

export const Emoji: React.FC<EmojiProps> = ({
	emojiEntity,
	size = 'default',
}) => {
	const emojiNode: React.ReactNode = emojiEntity.picture ? (
		<img
			src={emojiEntity.picture}
			alt={emojiEntity.name}
			title={emojiEntity.name}
			className="emoji__img"
		/>
	) : (
		<div>{emojiEntity.name}</div>
	);

	return <div className={`emoji emoji--${size}`}>{emojiNode}</div>;
};
